import React from 'react'
import { Container, Grid, Typography, Card, CardContent, Divider, CardHeader } from '@mui/material'

import PatientDataForm from '../components/PatientDataForm'
import { iconsObj } from '../icons/Icons'
import Footer from '../components/Footer'

type Props = {
  header: string
  description: string
  icon: React.ReactNode
}

const DescriptionCard: React.FC<Props> = (props) => {
  return (
    <Card variant='outlined' sx={{ mt: 2 }}>
      <CardContent>
        <Grid container direction='row' justifyContent='flex-start' alignItems='center'>
          <Grid item mx={3}>
            {props.icon}
          </Grid>
          <Divider orientation='vertical' flexItem />
          <Grid item xs mx={3}>
            <Typography fontSize={14} color='text.secondary' gutterBottom>
              {props.header}
            </Typography>
            <Typography variant='body2'>{props.description}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

const Home: React.FC = () => {
  return (
    <React.Fragment>
      <Container maxWidth='xl' sx={{ mt: 16 }}>
        <Grid container direction='row' justifyContent='space-around' alignItems='flex-start'>
          <Grid item xs={11} sm={10} md={10} lg={4} xl={3} mb={4}>
            <Grid container item direction='column' alignItems='flex-start' justifyContent='space-between'>
              <Grid container direction='row'>
                <Grid item xs={3} sm={3} md={3} lg={5} xl={3}>
                  <img src={process.env.PUBLIC_URL + '/logo.svg'} alt='Logo' style={{ width: 'auto', height: 'auto', maxWidth: '100px', maxHeight: '100px' }} />
                </Grid>
                <Grid item xs={9} sm={9} md={9} lg={7} xl={9} mb={4}>
                  <Grid container item direction='column' justifyContent='flex-start' alignItems='flex-start'>
                    <Grid item>
                      <Typography variant='h3' fontSize={32} pl={3}>
                        Medical Analyses
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant='subtitle1' fontWeight={1} pl={3}>
                        Precision in Patient Analysis.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Typography variant='subtitle1' my={4} fontWeight='regular' align='justify'>
                  Get important medical analyses and calculations based on the patient's medical anamnesis, laboratory findings and other diagnostic data.
                </Typography>
              </Grid>
              <Grid item>
                <DescriptionCard
                  header='Drugs Interactions'
                  description="Copy patient's medical anamnesis into the web form and get all patient's drug interactions and related SmPCs."
                  icon={iconsObj.DRUGS_INTERACTIONS}
                />
              </Grid>
              <Grid item>
                <DescriptionCard
                  header='Dosage Adjustment'
                  description="Find out need of drug dosage adjustement according to patient's renal function, hepatic parameters, weight and age."
                  icon={iconsObj.DOSAGE_ADJUSTMENT}
                />
              </Grid>
              <Grid item>
                <DescriptionCard
                  header='Medical Calculations'
                  description='Get the results of different medical calculations in an easy way. Centrally filled patient data are reflected in all calculations.'
                  icon={iconsObj.MEDICAL_CALCULATION}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={11} sm={10} md={10} lg={7} xl={8} mb={3}>
            <Card>
              <CardHeader
                component={() => (
                  <Typography variant='h5' m={3}>
                    Patient's data
                  </Typography>
                )}
              />
              <PatientDataForm />
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </React.Fragment>
  )
}

export default Home
