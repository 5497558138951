import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AccordionDetails, Divider, Grid, TextField, AccordionActions, Box, Button } from '@mui/material'

import { PatientFormData, basicPatientRadioForm, basicPatientTextField, diagnosisAndOtherDataRadioForm, laboratoryResultsTextField } from './Constants'
import { useAuth } from '../context/AuthContext'
import LoginDialog from './dialogs/LoginDialog'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import DecimalInputField from './DecimalInputField'
import OptionsInputField from './OptionsInputField'

type Props = {
  formData?: PatientFormData
}

const PatientDataForm: React.FC<Props> = ({ formData }) => {
  const [patientData, setPatientData] = useState<PatientFormData>()
  const [openLoginModal, setOpenLoginModal] = useState<boolean>(false)

  const { control, setValue, handleSubmit } = useForm<PatientFormData>()

  const { isTokenValid } = useAuth()

  const navigate = useNavigate()

  const onSubmit: SubmitHandler<PatientFormData> = (data) => {
    setPatientData(data)

    if (isTokenValid() === true) {
      navigate('/patient-results', { state: data })
    } else {
      setOpenLoginModal(true)
    }
  }

  const handleInputChange = (name: keyof PatientFormData, value: string) => {
    setValue(name, value)
  }

  useEffect(() => {
    if (formData) {
      Object.keys(formData).forEach((key) => {
        setValue(key as keyof PatientFormData, formData[key as keyof PatientFormData])
      })
    }
  }, [formData])

  return (
    <React.Fragment>
      <AccordionDetails
        sx={{ minHeight: 'calc(62vh)', height: '1rem', mx: 2 }}
        style={{
          overflow: 'hidden',
          overflowY: 'scroll',
        }}
      >
        <Divider sx={{ mb: 4 }}>Patient's medical anamnesis</Divider>

        <Controller
          name='medical_anamnesis'
          control={control}
          defaultValue={formData !== undefined ? formData.medical_anamnesis : ''}
          render={({ field }) => (
            <TextField
              fullWidth
              id='medical_anamnesis'
              name='medical_anamnesis'
              label="Patient's medical anamnesis"
              helperText='Example: Torvacard 20-0-0 mg, Nilemdo 180 mg 1-0-0, Nustendi 180/10 mg 1-0-0, indapamid 1,5 mg 1-0-0...'
              value={field.value}
              multiline
              rows={3}
              onChange={(e) => handleInputChange('medical_anamnesis' as keyof PatientFormData, e.target.value)}
              sx={{ width: '100%' }}
            />
          )}
        />

        <Divider sx={{ my: 4 }}>Basic patient's data</Divider>

        <Grid container spacing={3} justifyContent={'left'}>
          {Object.values(basicPatientTextField).map((data) => {
            return (
              <Grid item key={`${data.id}-grid`} xs={11} sm={12} md={6} lg={4} xl={4}>
                <DecimalInputField
                  label={data.label}
                  units={data.units}
                  defaultValue={formData ? formData[data.id as keyof PatientFormData] : ''}
                  id={data.id}
                  control={control}
                  setValue={setValue}
                />
              </Grid>
            )
          })}
        </Grid>
        <Grid container spacing={3} justifyContent={'left'} sx={{ mt: 0 }}>
          {Object.values(basicPatientRadioForm).map((data) => {
            return (
              <Grid item key={`${data.id}-grid`} xs={11} sm={12} md={6} lg={4} xl={4}>
                <OptionsInputField
                  label={data.label}
                  options={data.options as []}
                  defaultValue={formData !== undefined ? formData[data.id as keyof PatientFormData] : ''}
                  id={data.id}
                  control={control}
                />
              </Grid>
            )
          })}
        </Grid>

        <Divider sx={{ my: 4 }}>Laboratory results</Divider>

        <Grid container spacing={3} justifyContent={'left'}>
          {Object.values(laboratoryResultsTextField).map((data) => {
            return (
              <Grid item key={data.id} xs={11} sm={12} md={6} lg={4} xl={4}>
                <DecimalInputField
                  label={data.label}
                  units={data.units}
                  defaultValue={formData !== undefined ? formData[data.id as keyof PatientFormData] : ''}
                  id={data.id}
                  control={control}
                  setValue={setValue}
                />
              </Grid>
            )
          })}
        </Grid>

        <Divider sx={{ my: 4 }}>Diagnosis and other data</Divider>

        <Grid container spacing={3} justifyContent={'left'}>
          {Object.values(diagnosisAndOtherDataRadioForm).map((data) => {
            return (
              <Grid item key={data.id} xs={11} sm={12} md={6} lg={4} xl={4}>
                <OptionsInputField
                  label={data.label}
                  options={data.options as []}
                  defaultValue={formData !== undefined ? formData[data.id as keyof PatientFormData] : ''}
                  id={data.id}
                  control={control}
                />
              </Grid>
            )
          })}
        </Grid>
      </AccordionDetails>
      <AccordionActions sx={{ marginTop: 'auto', height: '100%', justifyContent: 'flex-end' }}>
        <Box>
          <Grid container spacing={1} sx={{ p: 1, px: 2 }}>
            <Grid item>
              <Button variant='outlined' color='primary' onClick={handleSubmit(onSubmit)}>
                Analyze
              </Button>
            </Grid>
          </Grid>
        </Box>
      </AccordionActions>

      <LoginDialog open={openLoginModal} setOpen={setOpenLoginModal} onSuccessLogin={() => navigate('/patient-results', { state: patientData })} />
    </React.Fragment>
  )
}

export default PatientDataForm
